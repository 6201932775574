import { FuseRouteConfigsType } from '@fuse/utils/FuseUtils';
import CatalogConfig from './catalog/CatalogConfig';
import CustomerEntryConfig from './customer-entry/CustomerEntryConfig';
import OrderEntryUploadConfig from './order-entry-upload/OrderEntryUploadConfig';
import OrderErrorsConfig from './order-errors/OrderErrorsConfig';
import OrderStatusConfig from './order-status/OrderStatusConfig';
import FulfillmentEntryConfig from './fulfillment/FulfillmentEntryConfig';
/**
 * The pages routes config.
 */
const pagesConfigs: FuseRouteConfigsType = [
	CatalogConfig,
	CustomerEntryConfig,
	OrderEntryUploadConfig,
	OrderErrorsConfig,
	OrderStatusConfig,
	FulfillmentEntryConfig
];

export default pagesConfigs;
