import { lazy } from 'react';

const FulfillmentEntry = lazy(() => import('./FulfillmentEntry'));

/**
 * The Example page config.
 */
const FulfillmentEntryConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: 'fulfillment-entry',
			element: <FulfillmentEntry />,
			auth:['admin']		 
		}
	]
};

export default FulfillmentEntryConfig;
