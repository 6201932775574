import { lazy } from 'react';

const OrderErrors = lazy(() => import('./OrderErrors'));

/**
 * The Example page config.
 */
const OrderErrorsConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: 'order-errors',
			element: <OrderErrors />
		}
	]
};

export default OrderErrorsConfig;
