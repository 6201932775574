import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import settingsConfig from 'app/configs/settingsConfig';
import { FuseRouteConfigsType, FuseRoutesType } from '@fuse/utils/FuseUtils';
import SignInConfig from '../main/sign-in/SignInConfig';
import SignUpConfig from '../main/sign-up/SignUpConfig';
import SignOutConfig from '../main/sign-out/SignOutConfig';
import ConfirmationPage from '../main/confirmation/ConfirmationConfig';
import Error404Page from '../main/404/Error404Page';
import AuthorizeAmazonConfig from '../main/authorize-amazon/AuthorizeAmazonConfig';
import PagesConfig from '../main/pagesConfigs';
import ForgotPasswordPagesConfig from '../main/forgot-password/forgotPasswordPagesConfig';
import ResetPasswordPagesConfig from '../main/reset-password/resetPasswordPagesConfig';

const routeConfigs: FuseRouteConfigsType = [	
	SignOutConfig,
	SignInConfig,
	SignUpConfig,
	ForgotPasswordPagesConfig,
	ResetPasswordPagesConfig,
	ConfirmationPage,
	AuthorizeAmazonConfig,
	...PagesConfig
];

/**
 * The routes of the application.
 */
const routes: FuseRoutesType = [
	...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
	{
		path: '/',
		element: <Navigate to="/order-status" />,
		auth: settingsConfig.defaultAuth
	},
	{
		path: 'loading',
		element: <FuseLoading />
	},
	{
		path: '404',
		element: <Error404Page />
	},
	{
		path: '*',
		element: <Navigate to="404" />
	}
];

export default routes;
