import { lazy } from 'react';

const Catalog = lazy(() => import('./Catalog'));

/**
 * The Example page config.
 */
const CatalogConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: 'catalog',
			element: <Catalog />
		}
	]
};

export default CatalogConfig;
