import { lazy } from 'react';

const OrderStatus = lazy(() => import('./OrderStatus'));

/**
 * The Example page config.
 */
const OrderStatusConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: 'order-status',
			element: <OrderStatus />
		}
	]
};

export default OrderStatusConfig;
