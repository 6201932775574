import i18next from 'i18next';
import { FuseNavigationType } from '@fuse/core/FuseNavigation/types/FuseNavigationType';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

/**
 * The navigationConfig object is an array of navigation items for the Fuse application.
 */
const navigationConfig: FuseNavigationType = [
	{
		id: 'order-status-component',
		title: 'Order Status',
		type: 'item',
		icon: 'heroicons-outline:truck',
		url: 'order-status'		
	},
	{
		id: 'customer-entry-component',
		title: 'Customer Entry',
		type: 'item',
		icon: 'heroicons-outline:pencil',
		url: 'customer-entry'	
	},
	{
		id: 'order-entry-upload',
		title: 'Order Entry Upload',
		type: 'item',
		icon: 'heroicons-outline:upload',
		url: 'order-entry-upload'		
	},
	{
		id: 'catalog-component',
		title: 'Catalog/Inventory',
		type: 'item',
		icon: 'heroicons-outline:book-open',
		url: 'catalog'		
	},
	{
		id: 'order-errors-component',
		title: 'Order Errors',
		type: 'item',
		icon: 'heroicons-outline:exclamation',
		url: 'order-errors'
		
	},{
		id: 'fulfillment-entry',
		title: 'Fulfillment Entry',
		type: 'item',
		icon: 'heroicons-outline:clipboard-check',
		url: 'fulfillment-entry',
		auth: 'admin'
	},
	{
		id: 'auth-amazon-component',
		title: 'SP-API Authorize',
		type: 'item',
		icon: 'heroicons-outline:key',
		url: 'authorize-amazon'
	},{
		id: 'auth',
		title: 'Auth',
		type: 'group',
		icon: 'verified_user',
		children: [
			{
				id: 'sign-out',
				title: 'Sign out',
				type: 'item',
				url: 'sign-out',
				icon: 'exit_to_app'
			}
		]
	}
];

export default navigationConfig;
