import { lazy } from 'react';

const AuthorizeAmazon = lazy(() => import('./AuthorizeAmazon'));

/**
 * The Example page config.
 */
const AuthorizeAmazonConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: 'authorize-amazon',
			element: <AuthorizeAmazon />
		}
	]
};

export default AuthorizeAmazonConfig;
