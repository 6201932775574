import { lazy } from 'react';

const CustomerEntry = lazy(() => import('./CustomerEntry'));

/**
 * The Example page config.
 */
const CustomerEntryConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: 'customer-entry',
			element: <CustomerEntry />
		}
	]
};

export default CustomerEntryConfig;
