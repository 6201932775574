import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { FuseRouteConfigType } from '@fuse/utils/FuseUtils';
import authRoles from '../../auth/authRoles';

const ClassicResetPasswordPage = lazy(() => import('./ClassicResetPasswordPage'));

/**
 * The reset password pages config.
 */
const resetPasswordPagesConfig: FuseRouteConfigType = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: 'reset-password',
			children: [
				{
					path: '',
					element: <Navigate to="classic" />
				},
				{
					path: 'classic',
					element: <ClassicResetPasswordPage />
				}				
			]
		}
	]
};

export default resetPasswordPagesConfig;
