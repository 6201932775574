import { FuseRouteConfigType } from '@fuse/utils/FuseUtils';
import authRoles from '../../auth/authRoles';
import { lazy } from 'react';

const ConfirmationPage = lazy(() => import('./ConfirmationPage'));

/**
 * The Example page config.
 */
const ConfirmationConfig: FuseRouteConfigType = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
    auth: authRoles.onlyGuest,
	routes: [
		{
			path: 'confirmation-page',
			element: <ConfirmationPage />
		}
	]
};

export default ConfirmationConfig;
